// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary[900]
        },
        gutterBottom: {
          marginBottom: 12
        }
      }
    }
  };
}

// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
// import ContactUsIcon from '@mui/icons-material/AddIcCallOutlined';
import AnnouncementIcon from '@mui/icons-material/CampaignOutlined';
// icons
const icons = { AnnouncementIcon };

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_announcement = {
  id: 'market_announcement',
  title: <FormattedMessage id="Announcements" />,
  type: 'item',
  url: '/market/announcement',
  icon: { image: false, icon: icons.AnnouncementIcon }
};
const market_announcement_ = {
  id: 'market_announcement',
  title: <FormattedMessage id="Announcements" />,
  type: 'collapse',
  icon: { image: false, icon: icons.AnnouncementIcon },
  children: [
    {
      id: 'announcement_',
      title: <FormattedMessage id="Announcements" />,
      type: 'item',
      url: '/market/announcement'
    }
  ]
};

export { market_announcement, market_announcement_ };

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import BarChartIcon from '@mui/icons-material/BarChart';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_sales = {
  id: 'market_sales',
  title: <FormattedMessage id="Sales" />,
  type: 'item',
  url: '/market/sales',
  icon: { image: false, icon: BarChartIcon }
};

const market_sales_ = {
  id: 'market_sales',
  title: <FormattedMessage id="Sales" />,
  type: 'collapse',
  icon: { image: false, icon: BarChartIcon },
  children: [
    {
      id: 'market_sales_',
      title: <FormattedMessage id="Sales" />,
      type: 'item',
      url: '/market/sales'
    }
  ]
};

export { market_sales, market_sales_ };
